<h1 mat-dialog-title>{{ titleText }}</h1>
<p class="guidance-text">
  Please see the help pages for guidance on
  <a href="https://help.futuredmrb.co.uk/" target="_blank">using associated documents</a>.
</p>

<form #associatedDocumentInformationForm="ngForm" style="position: relative">
  <mat-dialog-content *ngIf="!remove">
    <mat-form-field class="modal-field-styling-dropdown">
      <mat-select
        placeholder="Associated Document Type"
        name="associatedDocumentType"
        [disabled]="edit"
        [(value)]="associatedDocumentType"
        aria-label="select associated document type"
      >
        <mat-option *ngFor="let type of associatedDocumentTypes" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <div class="national-variation-toggle-container">
      <mat-slide-toggle
        (change)="onToggleChange($event)"
        [checked]="hasNationalVariations"
        class="national-variation-toggle"
        color="primary"
      ></mat-slide-toggle>
      <p>{{ getHasNationalVariationText() }}</p>
    </div>

    <mat-form-field class="modal-field-styling-freetext">
      <input
        autocomplete="off"
        matInput
        placeholder="Title"
        [(ngModel)]="title"
        maxLength="100"
        name="title"
        required
        carsRequiredNonWhitespace
      />
      <mat-hint>Title must be < 100 characters</mat-hint>
      <mat-error>Please provide a valid title</mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <div class="button-container">
    <button mat-raised-button aria-label="Cancel" (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      [color]="remove ? 'warn' : 'primary'"
      aira-label="Confirm action"
      (click)="confirm(remove)"
      [disabled]="!remove && !title"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</form>
