<h3>Associated documents</h3>
<div class="guidance-text">
  Guidance text about adding associated documents -
  <a href="https://help.futuredmrb.co.uk/" target="_blank">see here for guidance</a>
</div>
<div *ngIf="!hasChildren" class="mat-hint">No associated documents added</div>
<div *ngIf="hasChildren">
  <table>
    <thead>
      <tr style="text-align: left">
        <th>Reference</th>
        <th>Title</th>
        <th class="national-variations-header">National variations</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let associatedDocumentInformation of associatedDocumentInformationFragments">
        <td class="reference">{{ associatedDocumentInformation.reference }}</td>
        <td class="title">{{ associatedDocumentInformation.title }}</td>
        <td class="national-variations">{{ getHasNationalVariations(associatedDocumentInformation) }}</td>
        <td class="actions">
          <button
            [disabled]="!canUpdate()"
            [matTooltip]="!canUpdate() ? (lockedText | async) : 'Edit'"
            matTooltipPosition="before"
            [matTooltipShowDelay]="tooltipDelay"
            mat-icon-button
            [attr.aria-label]="'Edit'"
            (click)="openModal(associatedDocumentInformation)"
          >
            <div>
              <mat-icon>edit</mat-icon>
            </div>
          </button>
          <button
            [disabled]="!canUpdate()"
            [matTooltip]="!canUpdate() ? (lockedText | async) : 'Remove'"
            matTooltipPosition="after"
            [matTooltipShowDelay]="tooltipDelay"
            mat-icon-button
            [attr.aria-label]="'Remove'"
            (click)="openModal(associatedDocumentInformation, true)"
          >
            <div>
              <mat-icon>delete</mat-icon>
            </div>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="button-container">
  <button
    [matTooltip]="!canUpdate() ? (lockedText | async) : 'Add'"
    [disabled]="!canUpdate()"
    mat-flat-button
    color="primary"
    (click)="openModal()"
  >
    <mat-icon>add</mat-icon> Add an associated document
  </button>
</div>
<hr />
